.download-picto {
  bottom: -12px;
  right: -20px;
  width: 77px;
  height: 41px;
  .st0{
    fill:$pr-primary;
  }
  .st1{
    fill:$pr-white;
  }
}

.banner-download{
  .skew{
    transform: translateX(-50px) skew(-30deg);
  }
}

.hero{
  &-image{
    filter: blur(2px);
    -webkit-filter: blur(2px);
    height: 100%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-text{
    background-color: rgba(0,0,0,.2);
    position: relative;
    top: -100%;
    width: 100%;
  }
}

.files{
  &-title{
    border-bottom: 1px solid $pr-primary;
    padding-bottom: .3em;
    margin-bottom: 1em;
  }
}

.download-icon{
  >a{
    padding: 1em;
    max-width: 220px;
  }
}

.btn-master{
  background-color: $white;
  border: 1px solid $pr-gray-1;
  color: $pr-gray-1;
  &:hover, &:active, &:focus{
    background-color: $pr-primary;
    border-color: $pr-primary;
    color: $white;
  }
}

.collapseFilter, .collapseSort{
  min-width: 255px;
  z-index: 2;
  //top: 100%;
  .card{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $white;
    &-header{
      border-radius: 0;
      border: none;
      a{
        font-size: 1rem;
        line-height: 1.11rem;
        &:before {
          content: "\25bc";
          float: right;
          transition: all 0.5s;
          margin-left: 1em;
          transform: rotate(180deg);
        }
      }
      &.collapsed{
        a{
          &:before{
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.collapseSort{
  input{
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  label{
    padding-left: 0 !important;
    &:before, &:after{
      display: none;
    }
  }
}

