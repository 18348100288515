.wpcf7-form{
  .label-select, input[type=text], input[type=email], input[type=tel], input[type=submit], select, textarea{
    width: 100%;
  }

  span.wpcf7-list-item{
    margin: 0;
  }

  .form-group {
    position:relative;
    margin-top: 15px;
    margin-bottom: 15px;
    & + .form-group {
      //margin-top: 30px;
    }
  }

  .form-label {
    position: absolute;
    left: auto;
    top: 10px;
    color: #999;
    background-color: transparent;
    z-index: 10;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    cursor: text;
    padding-left: 10px;
  }

  .focused .form-label {
    transform: translateY(-125%);
    font-size: .75em;
    cursor: default;
    color: $pr-primary;
    padding-left: 0;
  }

  .form-input {
    position: relative;
    padding: 13px 0 5px 10px;
    width: 100%;
    outline: 0;
    border: 0;
    border-radius: 0;
    box-shadow: 0 1px 0 0 #e5e5e5;
    transition: box-shadow 150ms ease-out;
    min-width: auto;
    background-color: transparent;
    -webkit-appearance: none;
    &:focus {
      box-shadow: 0 2px 0 0 $pr-primary;
    }
  }

  .wpcf7-textarea{
    border: 1px solid #e5e5e5;
  }

  .form-input.filled {
    //box-shadow: 0 2px 0 0 lightgreen;
  }

  .btn-submit{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $pr-primary;
    border-radius: 0;
    input{
      border: none;
      background-color: transparent;
      color: $white;
    }
    span{
      position: absolute;
    }
    &:hover:not(.disabled){
      background-color: $pr-secondary;
    }
  }

  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked) + span,
  [type="checkbox"]:checked + span {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + span:before,
  [type="checkbox"]:checked + span:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 1.25em; height: 1.25em;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + span:after,
  [type="checkbox"]:checked + span:after {
    content: '\2713\0020';
    position: absolute;
    top: .15em; left: .22em;
    font-size: 1.3em;
    line-height: 0.8;
    color: $pr-primary;
    transition: all .2s;
    font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + span:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + span:before,
  [type="checkbox"]:disabled:checked + span:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  [type="checkbox"]:disabled:checked + span:after {
    color: #999;
  }
  [type="checkbox"]:disabled + span {
    color: #aaa;
  }
  /* accessibility */
  [type="checkbox"]:checked:focus + span:before,
  [type="checkbox"]:not(:checked):focus + span:before {
    border: 2px dotted blue;
  }
}

.label-select, .pp-country{
  margin: 8px 0 15px 0;
  position: relative;
  min-width: 15em;
  select{
    border: none;
    box-shadow: 0 1px 0 0 #e5e5e5;
    background-color: transparent;
    color: #999;
    padding: 12px 0px 5px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    border: 0 !important;
    background-image: none;
    cursor: pointer;
    width: 100%;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  &::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0 1em;
    color: #999;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
  }
  &:hover::after {
    color: $pr-secondary;
  }
}