.navbar-advantage-nav{
  li{
    font-size: 0.75em;
  }
}

.nav{
  &-advantage{
    &-item{
      &-link {
        color: $pr-gray-1;
      }
    }
  }
}

.pagination{
  .page-item{
    .page-link{
      color: $pr-primary;
    }
    &.active{
      .page-link{
        color: $white;
        background-color: $pr-primary;
      }
    }
  }
}

//.navbar-toggler{
//  font-size: .8rem;
//  height: 32px;
//}

.btn-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 16px;
  background-color: transparent;
  color: inherit;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  transition: .3s ease;

  &:focus {
    outline: none;
  }

  &__text {
    margin-left: 10px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1;
  }

  &__bars {
    display: block;
    position: relative;
    width: 22px;
    height: 2px;
    background-color: $pr-primary;
    transition: .3s;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $pr-primary;
      transition: .3s;
    }

    &:before {
      transform: translate(0, -7px);
    }

    &:after {
      transform: translate(0, 7px);
    }
  }

  &.menu-open {

    .btn-menu__bars {
      background-color: transparent;

      &:before, &:after {
        background-color: $white;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.info-sci{
  &-btn{
    height: 32px;
  }
  &-img{
    height: 100%;
  }
}

$n : nav;

.#{$n}{
  &-tabs{
    border-bottom: 3px solid $pr-primary;
    .#{$n}-item{
      //transform: skew(-30deg);
      .#{$n}-link{
        //transform: skew(30deg);
        border: none;
        border-radius: 0;
        color: $pr-gray-1;
        background-color: transparent;
        &.active{
          background-color: $pr-primary;
          color: $white;
        }
        &:hover{
          background-color: $pr-secondary;
          color: $white;
        }
      }
    }
  }
}

.btn-mobile-sidebar{
  min-width: 46px;
}