.search-form {
  position: relative;
  z-index: 20;
}

.search-field {
  background-color: transparent;
  background-image: url(https://api.ning.com:80/files/dr2jDpSXWFhyuoGJ4YR5Fu-PwuqLz70YxRaUcpdKNxf2HI5ECzddyx3NHuPz-MB4ujGS4rEtdtS-aQoFlQrtv*XwVOu*AvC8/searchicon.png);
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: 1px solid $pr-primary;
  cursor: pointer;
  height: 32px;
  margin: 3px 0;
  padding: 0 1.5rem;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition:         width 400ms ease, background 400ms ease;
  width: 0;
}

.search-field:focus {
  background-color: #fff;
  border: 2px solid black;
  cursor: text;
  outline: 0;
  width: 230px;
  + .search-svg{
    left: 90%;
  }
}

.search-submit {
  display: none;
}

input[type="search"] {
  -webkit-appearance: textfield;
}
.search-svg{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(360deg) skew(30deg);
  width: 30px;
  height: 30px;
}