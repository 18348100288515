.carousel{
  &-indicators{
    bottom: 0px;
    li {
      background: $pr-gray-3;
      height: 0;
      width: 24px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      padding: 0;
      opacity: 1;
    }
    .active{
      background: $pr-primary;
    }
  }
  &-indicators-shape{
    transform: skew(-30deg);
    flex-grow: 1;
  }
  &-control-next, &-control-prev{
    width: auto;
    z-index: 2;
  }
  &-control-round{
    display: flex;
    justify-content: center;
    align-items: center;
    background: $pr-secondary;
    padding: .5em;
    height: 6em;
  }
}