.skew{
  transform: skew(-30deg);
}
.noskew{
  transform: skew(30deg);
}

/* Skew only on right side */
.skew-right{
  position: relative;
  display: inline-block;
  background: $pr-secondary;
  z-index: 1;
}
.skew-right:after{
  content: " ";
  position: absolute;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $pr-secondary;
  transform-origin: bottom left;
  transform: skew(-30deg, 0deg);
}

.skew-left:before {
  content: "";
  transform: skew(-30deg);
  width: 10%;
  height: 100%;
  background: $pr-primary;
  position: absolute;
  transform-origin: top;
}