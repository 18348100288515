table, .table, .table-hover{
  margin-bottom: 1rem;
  border: 1px solid $pr-gray-3;
  tr{
    th{
      border: 0;
    }
  }
  thead{
    background-color: $pr-primary;
    color: $white;
    tr{
      th{
        font-weight: 400;
        vertical-align: top;
      }
    }
  }
  tbody{
    tr{
      &:nth-child(odd){

        background-color: $pr-gray-4;
      }
      &:nth-child(even){
        background-color: $white;
      }
      &:hover{
        background-color: $pr-gray-3;
      }
      td{
        padding: .5em 1.5em;
        border: 0;
      }
    }
  }
}