@include media-breakpoint-down(md) {
  #cookie-notice{
    z-index: 198 !important;
  }

  #customerly-container.customerly-position-right .customerly-launcher{
    right: 15px !important;
  }

  #customerly-container .customerly-launcher{
    bottom: 8px !important;
    width: 32px !important;
    height: 32px !important;
  }

  #customerly-container #customerly-launcher-button-icon,#customerly-container #customerly-launcher-button-icon svg,#customerly-container .customerly-bubble-background,#customerly-container .customerly-bubble-background svg{
    width: 32px !important;
    height: 32px !important;
  }

  #customerly-container #customerly-launcher-button-background{
    width: 28px;
    height: 29px;
  }
}