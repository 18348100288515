.sticky{
  position: fixed;
  width: 100%;
  z-index: 200;
  background: white;
  top: 0;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
  /*.sticky-hide{
    display: none;
  }
  .sticky-show{
    display: block;
  }*/
}

.nav-advantage-item-link.active {
  background-color: $pr-diphoterine;
  color:$pr-white;
}