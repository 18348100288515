.btn-pr{
  transform: skew(-30deg);
  flex-grow: 1;
  color: $pr-white;
}

.btn-pr-noskew {
  transform: skew(30deg);
}
.btn-share{
  width: 24px;
  height: 27px;
  fill: $pr-gray-1;
}
.btn-linkedin{
  width: 54px;
  height: 28px;
  .st0 {
    fill: #2F6296;
  }

  .st1 {
    fill: $pr-white;
  }
}

.btn-mail {
  width: 54px;
  height: 28px;

  .st0 {
    fill: $pr-white;
    stroke: $pr-primary;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: $pr-primary;
  }
}
.read-more{
  display: flex;
  margin-top: 1rem;
}

.btn-search{
  .btn{
    height: 32px;
  }
}