.bloc-image figcaption{
  text-align: center;
  width: 100%;
  padding: .3rem 0;
  background: $pr-secondary;
  color: #fff;
  //height: 1000%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5em;
}