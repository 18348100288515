.accordion{
  .card{
    border-radius: 0;
    border: none;
    &-header{
      border-radius: 0;
      border-bottom: 3px solid $pr-primary;
      position: relative;
      //background-color: $pr-gray-4;
      .btn{
        color: $pr-primary;
        white-space: normal;
      }
      //.triangle {
      //  background-image: linear-gradient(to right bottom, $pr-primary 0%, $pr-primary 50%, transparent 50%);
      //  position: absolute;
      //  width: 6%;
      //  height: 100%;
      //  top: 0;
      //  left: 0;
      //}
      a{
        font-size: 1rem;
        &:before {
          content: "\25bc";
          float: right;
          transition: all 0.5s;
          margin-left: 1em;
          transform: rotate(180deg);
        }
      }
      &.collapsed{
        a{
          &:before{
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
