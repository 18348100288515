.advantage-1{
  background-color: $pr-group-advantage-1;
}
.advantage-2{
  background-color: $pr-group-advantage-2;
}
.advantage-3{
  background-color: $pr-group-advantage-3;
}

.pie {
  position: relative;
  //padding: 0;
  width: 50em;
  height: 49.8em;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  .slice {
    transform: rotate(0deg) skewY(-50deg);
    overflow: hidden;
    position: absolute;
    top: 0; right: 0;
    width: 50%; height: 50%;
    transform-origin: 0% 100%;
    .slice-contents {
      transform: skewY(50deg); /* unskew slice contents */
      position: absolute;
      left: -100%;
      width: 200%;
      //height: 200%;
      border-radius: 50%;
      .img-block{
        overflow: hidden;
        .img-intern{
          position: relative;
          width: 110%;
        }
      }
    }
  }
  .slice:first-child {
    transform: rotate(0deg) skewY(-50deg);
    .img-intern{
      left: -25px;
    }
  }
  .slice:nth-child(2) {
    transform: rotate(40deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-40deg);
      left: -65px;
      top: -15px;
    }
  }
  .slice:nth-child(3) {
    transform: rotate(80deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-80deg);
      left: -42px;
      top: 10px;
    }
  }
  .slice:nth-child(4) {
    transform: rotate(120deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-120deg);
      left: -56px;
      top: -24px;
    }
  }
  .slice:nth-child(5) {
    transform: rotate(160deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-160deg);
      left: -54px;
    }
  }
  .slice:nth-child(6) {
    transform: rotate(200deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-200deg);
      left: -42px;
      top: -18px;
    }
  }
  .slice:nth-child(7) {
    transform: rotate(240deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-240deg);
      left: -54px;
    }
  }
  .slice:nth-child(8) {
    transform: rotate(280deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-280deg);
      left: -42px;
      top: -8px;
    }
  }
  .slice:nth-child(9) {
    transform: rotate(320deg) skewY(-50deg);
    .img-intern{
      transform: rotate(-320deg);
      left: -79px;
      top: -19px;
    }
  }

  .img-border{
    width: 16em;
    height: 7em;
    position: absolute;
    left: 51%;
    clip-path: ellipse(23.5em 25em at 1em 25em);
  }

  .img-block{
    position: absolute;
    left: 51%;
    width: 33%;
    top: .7em;
    clip-path: ellipse(23.2em 24.5em at 0em 25em);
  }
}

.dipho-logo{
  border: 10px solid white;
  border-radius:50%;
  color: white;
}

.dipho-logo-mobile{
  max-width: 360px;
  width: 100%;
}

@media all and (-ms-high-contrast:none)
{
  .dipho-logo {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  } /* IE10 */
  *::-ms-backdrop, .dipho-logo {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  } /* IE11 */
  *::-ms-backdrop, .well_img img{
    flex-shrink: 0;
  }
}

.basic_well{
  width: 105%;
  .title-advantage{
    position: absolute;
    color: $white;
    padding: .5rem 1.5rem;
    max-width: 17em;
    min-width: 12em;
    span{
      display: inline-block;
    }
    &:first-of-type{
      left: 42em;
      bottom: 50em;
    }
    &:nth-of-type(2){
      left: 60em;
      bottom: 38em;
    }
    &:nth-of-type(3){
      left: 61em;
      bottom: 16em;
    }
    &:nth-of-type(4){
      left: 51em;
      top: 45em;
    }
    &:nth-of-type(5){
      left: 30em;
      top: 51em;
    }
    &:nth-of-type(6){
      right: 52em;
      top: 45em;
    }
    &:nth-of-type(7){
      right: 61em;
      top: 31em;
    }
    &:nth-of-type(8){
      right: 58em;
      bottom: 35em;
    }
    &:nth-of-type(9){
      right: 40em;
      bottom: 50em;
    }
  }
}

.hover_well{
  //display: none;
  width: 72%;
  &_mouse{
    width: 50em;
    height: 49.8em;
    border-radius: 50% 0 0 0;
    overflow: hidden;
  }
  &_list{
    color: white;
    max-height: 400px;
    height: 400px;
    font-size: 12px;
    line-height: 2.4rem;
    position:relative;
    right: 6px
  }
  .adv-clickable{
    li{
      width: 100%;
      position: absolute;
      clip-path: polygon(0% 0%, 100% 53%, 100% 47%, 0% 100%);
      transform-origin: right center;
      bottom: -27px;
    }
    &.active{
      li{
        background: $pr-gray-4;
        color: $pr-primary;
      }
    }
    &:first-child{
      li{
        transform: rotate(5deg);
        clip-path: polygon(0% 0%, 100% 53%, 100% 50%, 0% 100%);
      }
    }
    &:nth-child(2){
      li{
        transform: rotate(15deg);
      }
    }
    &:nth-child(3){
      li{
        transform: rotate(25deg);
      }
    }
    &:nth-child(4){
      li{
        transform: rotate(35deg);
      }
    }
    &:nth-child(5){
      li{
        transform: rotate(45deg);
      }
    }
    &:nth-child(6){
      li{
        transform: rotate(55deg);
      }
    }
    &:nth-child(7){
      li{
        transform: rotate(65deg);
      }
    }
    &:nth-child(8){
      li{
        transform: rotate(75deg);
      }
    }
    &:nth-child(9){
      li{
        transform: rotate(85deg);
      }
    }
  }
  .well_content, .well_img{
    display: flex;
    h3{
      color: $white;
    }
    color: $white;
  }
  .well_content {
    h3{
      color: $white;
    }
    color: $white;
  }
  .well-btn{
    border: 1px solid $white;
    transform: skew(-25deg);
    padding: 1em;
    max-width: 85%;
    text-align: center;
    align-self: center;
    &-link{
      color: $white;
      transform: skew(25deg);
      display: block;
    }
  }
  .well_img{
    .img-fluid{
      width: 80%;
    }
  }
}

.text-over-well{
  position: absolute;
  transform: rotate(50deg) skew(50deg);
  left: 8%;
  top: 29%;
  font-size: 24px;
  letter-spacing: .05em;
  font-weight: 600;
}

.list-group-adv{
  width: 32px;
  height: 32px;
}

.well-accordion{
  .card{
    border: none;
    border-radius: none;
    &-header{
      background: transparent;
      .btn{
        padding-left: 0;
      }
    }
    &-title{
      >a{
        text-decoration:none;
        display:block;
      }
    }
    &-header {
      a{
        font-size: 1rem;
        &::before {
          content: "\25bc";
          float: right;
          transition: all 0.5s;
        }
      }
      &.active{
        a{
          &::before{
            transform: rotate(180deg);
          }
        }
      }
    }
    &-body{
      .well-btn{
        display: block;
        text-align: center;
        padding: .5rem 1rem;
        margin: 1.5rem 0;
        a{
          display: inline-block;
        }
      }
    }
  }
  @for $i from 1 through 3 {
    .color-advantage-#{$i}{
      .card{
        &-link{
          color: nth($pr-groups-advantage, $i);
        }
        &-header{
          &.active{
            background-color: nth($pr-groups-advantage, $i);
            .card-link{
              color: $white;
            }
          }
        }
        &-body{
          .well-btn{
            border: 3px solid nth($pr-groups-advantage, $i);
            a{
              color: nth($pr-groups-advantage, $i);
            }
          }
        }
      }
    }
  }
}

