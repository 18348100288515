@include media-breakpoint-down(lg) {
  .nav-main{
    .navbar-nav{
      .nav{
        &-item, &-noskew, &-dropdown{
          transform: none;
          left: 0;
        }
      }
    }
  }

  .nav-title{
    padding: .7rem 0;
  }
  .navbar-advantage-nav{
    li{
      font-size: 1.2em;
    }
  }
  .widget_nav_menu{
    display: flex;
    width: 100%;
    ul{
      display: flex;
      flex-direction: column;
      list-style: none;
      width: 100%;
      padding-left: .5rem;
      padding-right: 1rem;
      //padding: 0 1rem;
      li{
        display: flex;
        justify-content: start;
        text-align: left;
        align-items: center;
        flex-grow: 1;
        a{
          color: $white;
          padding: .7rem 0;
        }
      }
    }
  }
  .navbar-advantage{
    .navbar-collapse{
      //position: relative;
      height: auto;
      top: 100%;
      border-bottom: 3px solid $pr-diphoterine;
      background: $white;
    }
    .navbar-toggler{
      font-size: 1rem;
      .chevron::before{
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        display: inline-block;
        height: 0.90em;
        left: 0;
        top: .25em;
        position: relative;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.90em;
        transition: all 300ms;
      }
      &.collapsed{
        .chevron::before{
          transform: rotate(135deg);
          top: -0.25em;
        }
      }
    }
  }
  .header{
    .nav{
      &-dropdown{
        width: 100%;
      }
      &-link{
        white-space: normal;
        max-width: 90%;
      }
    }
  }
  .dropdown{
    .plus{
      &:after {
        content: "\002B";
      }
    }
    &.show{
      .plus{
        &:after{
          content:'\2212';
        }
      }
      .dropdown-item{
        .plus{
          &:after {
            content: "\002B";
          }
        }
        &.show {
          .plus {
            &:after {
              content: '\2212';
            }
          }
        }
      }
    }
  }
  .dropdown-toggle{
    &:after{
      display: none;
    }
    .plus{
      &:after{
        font-size: 1.6rem;
        line-height: 3rem;
        border: none;
        margin: 0;
        position: absolute;
        right: .5em;
        top: 0;
      }
    }
  }
  #footer{
    overflow: hidden;
  }
}