.link-card{
  .card{
    background-color: $pr-secondary;
    overflow: hidden;
    border: none;
    border-radius: 0;
    &-header{
      background-color: $pr-secondary;
      color: white;
      h4{
        color: white;
      }
    }
    //&-image{
    //  max-height: 10em;
    //}
    .triangle{
      background-image: linear-gradient(to right bottom, $pr-primary 0%, $pr-primary 50%, transparent 50%);
      position: absolute;
      width: 45%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .4;
    }
  }
}