@include media-breakpoint-up(lg) {
  #content{
    margin-top: 136px;
  }

  #cookie-notice{
    margin-bottom: 0;
  }

  #menu-left{
    padding-right: 0;
  }

  .sidebar-left{
    max-width: 233px;
    .widget-gas{
      max-width: 233px;
    }
  }

  .nav-main{
    .nav{
      &-item{
        transform: skew(-30deg);
        &.nav-first{
          background-color: $pr-secondary;
          a{
            color: $white;
          }
          &:hover{
            background-color: $white;
          }
        }
      }
      &-skew{
        width: 121%;
        margin-left: -12%;
      }
      &-noskew{
        transform: skew(30deg);
      }
      //&-last{
      //  a{
      //    max-width: 7em;
      //  }
      //}
      &-dropdown{
        transform: skew(30deg);
      }
      &-list-item{
        border-radius: 0;
        border-bottom: 1px solid white;
        font-size: 100%;
        margin-bottom: 0;
        .#{$n}-link{
          padding-right: 2.5rem;
          text-align: left;
        }
      }
    }
  }

  .nav{
    &-skew{
      width: 121%;
      margin-left: -12%;
    }
  }

  .menu-item:hover {
    background-color: $pr-secondary;
  }

  .nav {
    &-main-item, &-list-item {
      &:hover {
        background-color: $pr-secondary;
      }
    }
  }

  .navbar-collapse{
    min-height: auto;
  }

  .link-card{
    .card{
      &-image{
        max-height: 16.4em;
      }
      .triangle{
        border-width: 25em 10em 0 0;
      }
    }
  }

  .title-advantage{
    display: none;
  }

  .footer{
    background: $pr-secondary;
  }
}