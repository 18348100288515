.wpcmtt-tinymce-tooltip {
  position: relative;
  &:after {
    content: "";
    color: $pr-primary;
    font-size: 1.2rem;
    margin-left: .2rem;
    background-image: url('../img/svg/info.svg');
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    display: inline-block;
  }
}