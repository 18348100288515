.bg-pr-primary {
  background: $pr-primary;
}

.bg-pr-secondary {
  background: $pr-secondary;
}

.bg-pr-tertiary {
  background: $pr-tertiary;
}

.bg-prevor {
  background: $pr-prevor;
}

.bg-prevor-light {
  background: #E1EBF7;
}

.bg-diphoterine {
  background: #F29400;
}

.bg-diphoterine-light {
  background: #FEEFDA;
}

.bg-hexafluorine {
  background: #9D62A0;
}

.bg-hexafluorine-light {
  background: #FDE0FF;
}

.bg-trivorex {
  background: #5B2E7C;
}

.bg-polycaptor {
  background: #218F7D;
}

.bg-polycaptor-light {
  background: #DEEEED;
}

.bg-levert {
  background: #004425;
}

.bg-acicaptal {
  background: #E75294;
}

.bg-basicaptal {
  background: #009EE0;
}

.bg-colopik {
  background: #38D430;
}

.bg-safurex {
  background: #8C7D70;
}

.bg-axoc {
  background: #BE1621;
}

.bg-axoc-light {
  background: #F5DCDF;
}

.bg-gray-1 {
  background: #1C1C1C;
}

.bg-gray-2 {
  background: #B8B8B8;
}

.bg-gray-3 {
  background: #DDDCDA;
}

.bg-gray-4 {
  background: #F7F7F7;
}