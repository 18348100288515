.border-1 {
  border: solid 1px;
}

.border-2{
  border: 2px solid;
}
.border-2-bottom{
  border-bottom: 2px solid;
}
.border-3-bottom{
  border-bottom: 3px solid;
}
.border-8 {
  border: 8px solid #00000000;
}
.border-pr-primary{
  border-color: $pr-primary;
}
.border-pr-diphoterine{
  border-color: $pr-diphoterine;
}

.border-pr-gray-3 {
  border-color: $pr-gray-3;
}
