.sidebar-left {
  position: fixed;
  z-index: 1;
  width: 100%;
  //max-width: 255px;
  .widget-gas {
    //max-width: 255px;
    font-family: $condensed;
    font-size: 1rem;
  }
  .menu-left-container {
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        a{
          border-bottom: dashed 1px;
          display: block;
          padding: .85rem 0;
          margin: 0 1rem;
          color: $white;
        }
        &:last-of-type{
          a{
            border: none;
          }
        }
        //&.current-menu-item, &.current_page_item {
        //  background-color: rgba($pr-secondary, .7);
        //}
      }
    }
  }
}

.sidebar-sticky {
  top: 188px;
  margin-bottom: 1.8em;
}

.sidebar-product-img{
  border: 1px solid $pr-gray-3;
}

.top-mobile-sidebar{
  p{
    margin: 0;
    font-size: .7rem;
  }
}

.bottom-mobile-sidebar{
  border-top: 2px solid $pr-gray-3;
}