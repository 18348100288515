// Custom text colors
.has-pr-primary-color {
  color: $pr-primary;
}

.has-pr-secondary-color {
  color: $pr-secondary;
}

.has-pr-tertiary-color {
  color: $pr-tertiary;
}

.has-pr-gray-1-color {
  color: $pr-gray-1;
}

.has-pr-gray-2-color {
  color: $pr-gray-2;
}

.has-pr-gray-3-color {
  color: $pr-gray-3;
}

.has-pr-gray-4-color {
  color: $pr-gray-4;
}

.has-pr-white-color {
  color: $pr-white;
}

.has-pr-prevor-color {
  color: $pr-prevor;
}

.has-pr-diphoterine-color {
  color: $pr-diphoterine;
}

.has-pr-hexafluorine-color {
  color: $pr-hexafluorine;
}

.has-pr-trivorex-color {
  color: $pr-trivorex;
}

.has-pr-polycaptor-color {
  color: $pr-polycaptor;
}

.has-pr-levert-color {
  color: $pr-levert;
}

.has-pr-acicaptal-color {
  color: $pr-acicaptal;
}

.has-pr-basicaptal-color {
  color: $pr-basicaptal;
}

.has-pr-colopik-color {
  color: $pr-colopik;
}

.has-pr-safurex-color {
  color: $pr-safurex;
}

// Custom background colors
.has-pr-primary-background-color {
  background-color: $pr-primary;
}

.has-pr-secondary-background-color {
  background-color: $pr-secondary;
}

.has-pr-tertiary-background-color {
  background-color: $pr-tertiary;
}

.has-pr-gray-1-background-color {
  background-color: $pr-gray-1;
}

.has-pr-gray-2-background-color {
  background-color: $pr-gray-2;
}

.has-pr-gray-3-background-color {
  background-color: $pr-gray-3;
}

.has-pr-gray-4-background-color {
  background-color: $pr-gray-4;
}

.has-pr-white-background-color {
  background-color: $pr-white;
}

.has-pr-prevor-background-color {
  background-color: $pr-prevor;
}

.has-pr-diphoterine-background-color {
  background-color: $pr-diphoterine;
}

.has-pr-hexafluorine-background-color {
  background-color: $pr-hexafluorine;
}

.has-pr-trivorex-background-color {
  background-color: $pr-trivorex;
}

.has-pr-polycaptor-background-color {
  background-color: $pr-polycaptor;
}

.has-pr-levert-background-color {
  background-color: $pr-levert;
}

.has-pr-acicaptal-background-color {
  background-color: $pr-acicaptal;
}

.has-pr-basicaptal-background-color {
  background-color: $pr-basicaptal;
}

.has-pr-colopik-background-color {
  background-color: $pr-colopik;
}

.has-pr-safurex-background-color {
  background-color: $pr-safurex;
}

// Default WordPress stylesheet.
.has-small-font-size {
  font-size: 13px;
}

.has-regular-font-size,
.has-normal-font-size {
  font-size: 16px;
}

.has-medium-font-size {
  font-size: 20px;
}

.has-large-font-size {
  font-size: 36px;
}

.has-larger-font-size,
.has-huge-font-size {
  font-size: 42px;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-left {
  /*rtl:ignore*/
  text-align: left;
}

.has-text-align-right {
  /*rtl:ignore*/
  text-align: right;
}