#searchModal{
  background-color: rgba($pr-secondary, .8);
  .header-search{
    &-input{
      padding: .7em 1.5em;
      font-size: 1.335em;
    }
    &-label {
      margin-top: 1em;
      margin-right: .5em;
    }
  }
}