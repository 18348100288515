.showCat, .showBen{
  color: $pr-white;
  //border-radius: 1rem;
  //font-size: .8rem;
  .value{
    background-color: $pr-primary;
    padding: .5rem 1rem;
  }
  .delSelectBen, .delSelectCat{
    font-weight: 600;
    font-size: .8rem;
    border-radius: 50%;
    background: $pr-white;
    color: $pr-primary;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
  }
}