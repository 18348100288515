.nav-advantage-item-link:hover {
    background-color: $pr-diphoterine;
    color:$pr-white;
}

.dropdown-item{
  &:hover{
    background-color: transparent;
  }
}

.header .nav-first{
  &:hover{
    background-color: $white;
    .nav-link{
      color: $pr-secondary;
    }
  }
}

.header .nav-list-item .nav-link{
  &:focus{
    background-color: transparent;
    outline: none;
  }
}

.header-search-input{
  &:focus, &:active, &:focus-within, &:visited{
    border: none;
    outline: none;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
.btn-pr:hover{
  color:$pr-white;
  background-color: $pr-secondary;
}

.download:hover .download-screenshot {
  border-color: $pr-tertiary;
}


.download:hover .download-legende {
  color: $pr-primary;
}
.download:hover .download-picto .st0 {
  fill: $pr-secondary;
}
.read-more:hover{
  color: $pr-secondary;
}

.carousel-indicators-shape:hover{
  background: $pr-primary;
  opacity: 1;
}

.adv-clickable{
  &:hover{
    li{
      background: $pr-primary;
      cursor: pointer;
      z-index: 0;
    }
  }
}