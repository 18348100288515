.testi{
  &-row{
    &:nth-of-type(n+6){
      display: none;
    }
  }
  &-show{
    margin-top: -5em;
    &-block{
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
      &.bg-none{
        background: none;
      }
    }
    &-btn{
      color: $pr-primary;
      &:hover{
        color: $pr-secondary;
      }
    }
    #testi-show-less{
      display: none;
    }
  }
  &-table{
    &-title{
      .skew{
        background-color: $pr-primary;
        color: $white;
      }
    }
    &-block{
      &-content{
        border-radius: .5em;
        color: $pr-secondary;
        font-size: 0.875em;
        font-family: $italic;
        &-author{
          color: $pr-tertiary;
          font-size: .85em;
          font-family: $regular;
        }
      }
    }
  }
}

.author{
  font-family: $italic;
}