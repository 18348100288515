@include media-breakpoint-up(xl) {
  .sidebar-left{
    max-width: 255px;
    .widget-gas{
      max-width: 255px;
    }
  }
  .page-template-trigor{
    overflow-x: hidden;
  }
  .title-advantage{
    display: block;
  }
}