#exit-popup{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: hidden;
  z-index: 3000000000;
  opacity: 0;
  //-webkit-transform: scale(0.5);
  //transform: scale(0.5);
  //-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
  //transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
#exit-popup .wrapper {
  position: relative;
  text-align: center;
  width: max-content;
}
#exit-popup_bg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .7;
  z-index: 2147483000;
}
#exit-popup_close {
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
  background-color: $pr-primary;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#exit-popup_close::before {
  content: "×";
  font: 400 26px/1em 'Roboto Slab', serif;
  color: $pr-white;
}

#exit-popup .exit-content {
  margin: 0 auto
}