/*!
 * Theme Name: Prevor Theme
 * Description: Theme wordpress prevor
 * Author: Les petits koalas roux
*/

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap-grid";

// SASS advanced statements
@import "variables/fonts";
@import "variables/colors";
@import "functions";
@import "mixins";
@import "typography";

// Bases
@import "base";

// Layouts
@import "layouts";

// Modules Wordpress (replace defaults styles)
@import "modules/wordpress/wp";
@import "modules/wordpress/has";
@import "modules/wordpress/cf7";

// Modules Boostrap (extend of Boostrap 4 styles)
@import "modules/bootstrap/bg";
@import "modules/bootstrap/border";
@import "modules/bootstrap/btn";
@import "modules/bootstrap/carousel";
@import "modules/bootstrap/font";
@import "modules/bootstrap/form";
@import "modules/bootstrap/nav";
@import "modules/bootstrap/table";
@import "modules/bootstrap/text";

// Modules
@import "modules/accordion";
@import "modules/blog";
@import "modules/cookie";
@import "modules/customerly";
@import "modules/cursor";
@import "modules/datatable";
@import "modules/download";
@import "modules/embed";
@import "modules/exit-popup";
@import "modules/figcaption";
@import "modules/fill";
@import "modules/filter";
@import "modules/first-aid";
@import "modules/footer";
@import "modules/gap";
@import "modules/header";
@import "modules/link-card";
@import "modules/modal";
@import "modules/read-more";
@import "modules/search";
@import "modules/shadow";
@import "modules/sidebar";
@import "modules/skew";
@import "modules/slider";
@import "modules/testimonials";
@import "modules/tooltips";
@import "modules/tprod";
@import "modules/well-advantage";

// States
@import "states/animations";
@import "states/hover";
@import "states/nth-child";

@import "states/media-breakpoint-down-xs";
@import "states/media-breakpoint-down-sm";
@import "states/media-breakpoint-down-md";
@import "states/media-breakpoint-down-lg";

@import "states/media-breakpoint-up-xs";
@import "states/media-breakpoint-up-sm";
@import "states/media-breakpoint-up-md";
@import "states/media-breakpoint-up-lg";
@import "states/media-breakpoint-up-xl";


@import "states/ie11";

// Alternates themes
@import "themes";