$f : footer;

.#{$f}{
  //border-top: 1.5em solid $white;
  background: $pr-primary;
  &-skew{
    width: 110%;
    margin-left: -7%;
  }
  &-title{
    font-family: $bold;
    font-size: 16px;
    margin-bottom: 1em;
  }
  &-list{
    font-family: $light;
    li{
      margin-bottom: 1em;
    }
    a{
      color: $pr-white;
      &:hover{
        color: $pr-white;
        text-decoration: underline;
      }
    }
  }
  &-contact{
    .#{$f}-list{
      li{
        margin-bottom: .5em;
      }
    }
  }
}