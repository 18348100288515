.fill-white {
  fill: #FFF;
}

.fill-gray-1 {
  fill: $pr-gray-1;
}

.fill-primary {
  fill: $pr-primary;
}

.fill-secondary {
  fill: $pr-secondary;
}

.fill-tertiary {
  fill: $pr-tertiary;
}