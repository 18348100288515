html{
  //filter: invert(1) hue-rotate(180deg);
}

body {
  font-family: $regular;
  background-color: white;
  font-size: 12px;
  text-rendering: optimizeSpeed;
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}
header, main{
  background-color: white;
}

#content{
  min-height: 55vh;
  margin-top: 90px;
  ul:not(#menu-left):not(.navbar-advantage-nav):not(.hover_well_list){
    margin: 1em 0;
  }
}

image, img{
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

//section:nth-child(n+4){
//  content-visibility: auto;
//  //contain-intrinsic-size: 1000px; /* Explained in the next section. */
//}

#footer{
  z-index: 1;
  position: relative;
  content-visibility: auto;
  //contain-intrinsic-size: 1000px; /* Explained in the next section. */
}

h1, h2, h3, h4, h5, h6{
  @include fontDefault;
  &.title-blue{
    color: $pr-primary;
  }
  &.title-white{
    color: $pr-white;
  }
}

h1{
  margin-bottom: 1.5rem;
  font-size: 2.5em;
  &.title-blue{
    font-size: 2em;
  }
}

h2{
  margin-bottom: 1.5rem;
  font-size: 2em;
  &.title-blue{
    font-size:1.625em;
  }
}

h3{
  margin-bottom: 1rem;
  font-size: 1.75em;
  &.title-blue{
    font-size: 1.375em;
  }
}

h4{
  margin-bottom: 1rem;
  font-size: 1.5em;
  &.title-blue{
    font-size: 1.1875em;
  }
}

h5{
  margin-bottom: 1rem;
  font-size: 1.25em;
  &.title-blue{
    font-size: 1em;
  }
}

h6{
  margin-bottom: .75rem;
  font-size: 1em;
  &.title-blue{
    font-size: .8125em;
  }
}

select::-ms-expand {
  display: none;
}

a {
  color: $pr-primary;
  text-decoration: none !important;
  &:hover{
    color: $pr-secondary;
  }
}

.page-template-basic #content li:not(.nav-item):not(.menu-item), .article-body li{
  margin-bottom: .5rem;
}

.hdr-logo-link img{
  max-height: 54px;
}

.btn{
  border-radius: 0;
}

.hpcard{
  p{
    display: none;
  }
}

/*.pr-player, .youtube{
  display: none;
}*/

.grecaptcha-badge { visibility: hidden; }

article{
  &.tease-post{
    opacity: 1;
    visibility: visible;
    position: relative;
    //transition: all 750ms ease 0s;
    backface-visibility: hidden;
    //transform-style: preserve-3d;
    //transform: scale(1) translate3d(0px, 0px, 0px);
    &.hidden{
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -1000;
      //transition: all 750ms ease 0s;
      backface-visibility: hidden;
      //transform-style: preserve-3d;
      //transform: scale(0.5) translate3d(0px, 0px, 0px);
    }
  }
}