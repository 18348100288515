.text-pr-primary {
  color: $pr-primary;
}

.text-pr-secondary {
  color: $pr-secondary;
}

.text-pr-tertiary {
  color: $pr-tertiary;
}

.text-prevor {
  color: $pr-prevor;
}

.text-prevor-light {
  color: #E1EBF7;
}

.text-diphoterine {
  color: #F29400;
}

.text-diphoterine-light {
  color: #FEEFDA;
}

.text-hexafluorine {
  color: #9D62A0;
}

.text-hexafluorine-light {
  color: #FDE0FF;
}

.text-trivorex {
  color: #5B2E7C;
}

.text-polycaptor {
  color: #218F7D;
}

.text-polycaptor-light {
  color: #DEEEED;
}

.text-levert {
  color: #004425;
}

.text-acicaptal {
  color: #E75294;
}

.text-basicaptal {
  color: #009EE0;
}

.text-colopik {
  color: #38D430;
}

.text-safurex {
  color: #8C7D70;
}

.text-axoc {
  color: #BE1621;
}

.text-axoc-light {
  color: #F5DCDF;
}

.text-gray-1 {
  color: #1C1C1C;
}

.text-gray-2 {
  color: #B8B8B8;
}

.text-gray-3 {
  color: #DDDCDA;
}

.text-gray-4 {
  color: #F7F7F7;
}

.text-default {
  color: #212529;
}

.text-red{
  color: $pr-group-advantage-3;
}